<template>
  <div class="wrapper" ref="wrapper">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import BetterScroll from "better-scroll";

export default {
  name: "Scroll",
  data() {
    return {
      scroll: null,
    };
  },
  props: {
    click: {
      type: Boolean,
      default: true,
    },
    probeType: {
      type: Number,
      default: 0,
    },
    pullUpLoad: {
      type: Boolean | Object,
      default() {
        return false;
      },
    },
    pullDownRefresh: {
      type:  Boolean | Object,
      default() {
        return false;
      },
    },
    mouseWheel: {
      type: Boolean | Object,
      default() {
        return false;
      },
    },
  },
  mounted() {
    //实例
    this.scroll = new BetterScroll(this.$refs.wrapper, {
      click: this.click, //是否传递点击事件
      probeType: this.probeType,  //派发 scroll 事件 0,1,2
      pullUpLoad: this.pullUpLoad, //是否开启上拉加载
      pullDownRefresh: this.pullDownRefresh, //是否开启下拉刷新
      mouseWheel: this.mouseWheel, // PC 端的鼠标滚轮
      useTransition:false,  //是否使用 CSS3 transition 动画
    });
    //监听滑动
    this.scroll.on("scroll", (position) => {
      // console.log(position)
      this.$emit("scroll", position);
    });
    //上拉加载更多
    this.scroll.on("pullingUp", () => {
      //   console.log('pullingUp')
      this.$emit("pullingUp");
    });
    //下拉刷新
    this.scroll.on("pullingDown", () => {
        // console.log('pullingDown')
      this.$emit("pullingDown");
    });
  },
  methods: {
    //   滚动到
    scrollTo(x, y, time = 1000) {
      this.scroll.scrollTo(x, y, time);
    },
    //上拉加载更多完毕调用
    finishPullUp() {
      this.scroll.finishPullUp();
    },
    // 下拉刷新完毕调用
    finishPullDown(){
      this.scroll.finishPullDown();
    },
    //重新计算 better-scroll
    refresh() {
      // console.log(123);
      this.scroll.refresh();
    },
  },
};
</script>

<style scoped>
</style>